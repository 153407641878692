import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Icon from "../components/Icon";
import SEO from "../components/seo";

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        showDescription={true}
        showContact={true}
        render={() => {
          return (
            <>
              <SEO title="404: Not Found" />
              <div className="container">
                <Icon name="frown" width={50} height={50} />
                <p>
                  You just hit a route that doesn&#39;t exist... the sadness.
                </p>
                <p>
                  <Link to="/">Home</Link>is where the <Icon name="heart" /> is
                </p>
              </div>
            </>
          );
        }}
      />
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
